import YEARN from "../images/tokens/yearn.svg";
import MKR from "../images/tokens/MakerDAO.svg";
import CRV from "../images/tokens/Curve.svg";
import COMP from "../images/tokens/Compound.svg";
import AAVE from "../images/tokens/Aave.svg";
import VENUS from "../images/tokens/Venus.svg";
import BEEFY from "../images/tokens/Beefy.svg";
import BAKE from "../images/tokens/Bake.svg";
import SUSHI from "../images/tokens/Sushi.svg";
import KEYFIPRO from "../images/tokens/KeyFiPro.svg";
import QUICK from "../images/tokens/QuickSwap.svg";
import UNI from "../images/icons/UniswapLogo.svg";
import CAKE from "../images/icons/PancakeSwap.svg";
import APE from "../images/icons/ApeSwap.svg";
import CREAM from "../images/tokens/Cream.svg";
import NEXO from "../images/tokens/Nexo.svg";
import INCH from "../images/tokens/1inch.svg";
import DYDX from "../images/dydx.svg";
import SELFKEY from "../images/tokens/SelfKey.svg";
import WALLETCONNECT from "../images/tokens/WalletConnect.svg";
import METAMASK from "../images/tokens/MetaMask.png";
import DAOairdrop from "../images/tokens/DAOairdrop.svg";
import ethBscBridge from "../images/tokens/eth-bsc-bridge.svg";
import alkemi from "../images/tokens/alkemi-logo.svg";
// import NEXO from "../images/Nexo.svg";

// New roadmap checkpoints should be added at the end!

export const RoadmapData = [
  {
    title: "Yearn.finance",
    logo: YEARN,
    category: "upcoming",
    pills: ["Integration", "Upcoming"],
  },
  {
    title: "MakerDAO",
    logo: MKR,
    category: "upcoming",
    pills: ["Integration", "Upcoming"],
  },
  {
    title: "Curve Pool",
    logo: CRV,
    category: "upcoming",
    pills: ["Integration", "Upcoming"],
  },
  {
    title: "Venus Protocol",
    logo: VENUS,
    category: "inprogress",
    pills: ["Integration", "In Progress"],
  },
  {
    title: "Beefy Finance",
    logo: BEEFY,
    category: "inprogress",
    pills: ["Integration", "In Progress"],
  },
  {
    title: "BakerySwap",
    logo: BAKE,
    category: "upcoming",
    pills: ["Integration", "Upcoming"],
  },
  {
    title: "SushiSwap",
    logo: SUSHI,
    category: "upcoming",
    pills: ["Integration", "Upcoming"],
  },
  {
    title: "Polygon Wallet",
    color: "#2F80ED",
    white: true,
    category: "completed",
    pills: ["Wallet", "Integration", "Completed"],
  },
  {
    title: "QuickSwap",
    logo: QUICK,
    category: "completed",
    pills: ["Integration", "Completed"],
  },
  {
    title: "Alkemi",
    logo: alkemi,
    category: "inprogress",
    pills: ["Borrow", "Integration", "In Progress"],
  },
  {
    title: "Aave (Polygon)",
    logo: AAVE,
    category: "inprogress",
    pills: ["Integration", "In Progress"],
  },
  {
    title: "Full DEX Data Visualization",
    logo: KEYFIPRO,
    category: "inprogress",
    pills: ["Integration", "In Progress", "KeyFi Pro"],
  },
  {
    title: "AI Recomendation Engine",
    logo: KEYFIPRO,
    category: "inprogress",
    pills: ["Integration", "In Progress", "KeyFi Pro"],
  },
  {
    title: "AI Market Analysis",
    logo: KEYFIPRO,
    category: "inprogress",
    pills: ["Integration", "In Progress", "KeyFi Pro"],
  },
  {
    title: "Dedicated Newsreader",
    logo: KEYFIPRO,
    category: "inprogress",
    pills: ["Integration", "In Progress", "KeyFi Pro"],
  },
  {
    title: "KEY & KEYFI Binance.com Airdrop",
    category: "completed",
    pills: ["Completed"],
  },
  {
    title: "Litepaper",
    category: "completed",
    pills: ["Completed"],
  },
  {
    title: "Token Economics Whitepaper",
    category: "completed",
    pills: ["Completed"],
  },
  {
    title: "Hacken Smart Contract Audit",
    category: "completed",
    pills: ["Completed"],
  },
  {
    title: "MetaMask",
    logo: METAMASK,
    category: "completed",
    pills: ["Wallet", "Integration", "Completed"],
  },
  {
    title: "WalletConnect",
    logo: WALLETCONNECT,
    category: "completed",
    pills: ["Wallet", "Integration", "Completed"],
  },
  {
    title: "SelfKey Wallet",
    logo: SELFKEY,
    category: "completed",
    pills: ["Wallet", "Integration", "Completed"],
  },
  {
    title: "Binance Smart Chain Wallet",
    category: "completed",
    pills: ["Completed"],
  },
  {
    title: "Compound",
    logo: COMP,
    category: "completed",
    pills: ["Deposits", "Borrowing", "Integration", "Completed"],
  },
  {
    title: "Aave (Ethereum)",
    logo: AAVE,
    category: "completed",
    pills: ["Borrowing", "Integration", "Completed"],
  },
  {
    title: "Uniswap",
    logo: UNI,
    category: "completed",
    pills: ["Swaps", "Full liquidity management", "Integration", "Completed"],
  },
  {
    title: "1Inch",
    logo: INCH,
    category: "completed",
    pills: ["Swaps", "Integration", "Completed"],
  },
  {
    title: "Curve Compound",
    logo: CRV,
    category: "completed",
    pills: ["cToken pool", "Integration", "Completed"],
  },
  {
    title: "dYdX",
    logo: DYDX,
    category: "completed",
    pills: ["Deposits", "Integration", "Completed"],
  },
  {
    title: "Foundational infrastructure",
    logo: KEYFIPRO,
    category: "completed",
    pills: ["KeyFi Pro", "Completed"],
  },
  {
    title: "ApeSwap",
    logo: APE,
    category: "completed",
    pills: ["Integration", "Completed"],
  },
  {
    title: "PancakeSwap",
    logo: CAKE,
    category: "completed",
    pills: ["v2 liquidity pool", "Integration", "Completed"],
  },
  {
    title: "Research Dashboard",
    logo: KEYFIPRO,
    category: "completed",
    pills: ["KeyFi Pro", "Completed"],
  },
  {
    title: "Smart Alerts",
    logo: KEYFIPRO,
    category: "completed",
    pills: ["KeyFi Pro", "Completed"],
  },
  {
    title: "Full Transaction History",
    category: "completed",
    pills: ["Completed"],
  },
  {
    title: "KEYFI Token Burn",
    category: "completed",
    pills: ["Completed"],
  },
  {
    title: "Strategy Manager",
    logo: KEYFIPRO,
    category: "completed",
    pills: ["KeyFi Pro", "Completed", "KeyFi Pro"],
  },
  {
    title: "Cross Chain Bridge",
    category: "completed",
    pills: ["High Priority", "Bridge", "Completed"],
  },
  {
    title: "KeyFi DAO Airdrop",
    logo: DAOairdrop,
    category: "completed",
    pills: ["Airdrop", "Completed"],
  },
  {
    title: "Track and Stack Multiple Wallets",
    logo: KEYFIPRO,
    category: "completed",
    pills: ["Completed", "KeyFi Pro"],
  },
  {
    title: "Monitor Multi-Chain Addresses",
    logo: KEYFIPRO,
    category: "completed",
    pills: ["Completed", "KeyFi Pro"],
  },
];
