import React from "react";
import styled from "styled-components";

const pillColor = {
  Wallet: "#2F80ED",
  Integration: "#F2C94C",
  Completed: "#6FCF97",
  Deposits: "#BB6BD9",
  Swaps: "#F2994A",
  "Add Liquidity": `#56CCF2`,
  "cToken pool": `#EB5757`,
  "KeyFi Pro": `#101926`,
  "In Progress": `#D4E7FF`,
  "Portfolio Tracker": `#005AD3`,
  "Full liquidity management": `#00ADD3`,
  "v2 liquidity pool": `#8E5928`,
  Staking: `#8B15C2`,
  Upcoming: `#CCD74C`,
  Borrowing: `#0F6967`,
  Borrow: `#0F6967`,
  Bridge: `#764E50`,
  Airdrop: `#689900`,
  "High Priority": `#0CF4EB`,
  "Medium Priority": `#CB00B7`,
};

const fontColor = {
  Wallet: "white",
  Integration: "#1b232e",
  Completed: "#1b232e",
  Deposits: "white",
  Swaps: "#1b232e",
  "Add Liquidity": "#1b232e",
  "cToken pool": "white",
  "KeyFi Pro": "white",
  "In Progress": "#1b232e",
  "Portfolio Tracker": "white",
  "Full liquidity management": "white",
  "v2 liquidity pool": "white",
  Staking: "white",
  Upcoming: "#1b232e",
  Borrowing: "white",
  Borrow: "white",
  Bridge: `white`,
  Airdrop: `white`,
  "High Priority": `#1b232e`,
  "Medium Priority": `white`,
};

const Wrapper = styled.div`
  padding: 0.25rem 1rem;
  background: ${(props) => props.background};
  border-radius: 30px;
  width: max-content;
  color: ${(props) => props.color};
  font-size: 10px;
  font-weight: 600;
`;

const RoadmapPill = ({ title }) => {
  return (
    <Wrapper background={pillColor[title]} color={fontColor[title]}>
      {title}
    </Wrapper>
  );
};

export default RoadmapPill;
