import React from "react";
import styled from "styled-components";
import RoadmapPill from "./RoadmapPill";

import { RoadmapData } from "../../data/RoadmapDataNew";
import { breakPoints } from "../theme";

import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  max-width: 1200px;
  margin: 5rem auto;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  gap: 50px;
  @media (max-width: ${breakPoints.lg}px) {
    flex-direction: column-reverse;
    align-items: center;
  }
  * {
    margin: 0;
  }
  .roadmap_column {
    width: 100%;
    max-width: 300px;
    .roadmap_column_header {
      display: flex;
      .roadmap_column_color {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 0.4rem;
        margin-top: 0.55rem;
      }
      .upcoming {
        background: #ccd74c;
      }
      .inprogress {
        background: ${(props) => props.theme.lightBlue2};
      }
      .completed {
        background: #6fcf97;
      }
      .roadmap_column_title {
        h2 {
          font-size: 24px;
          font-weight: 700;
          color: black;
        }
        h6 {
          font-size: 12px;
          font-weight: 400;
          color: #698095;
        }
      }
    }
  }
`;

const RoadmapColumnList = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 0.75rem;
  .roadmap_card {
    background: #f2f2f2;
    margin-bottom: 1rem;
    padding: 0.75rem 1rem;
    border-radius: 6px;
    border: 1px solid #bdbdbd;
    .roadmap_card_header {
      display: flex;
      margin-bottom: 1rem;
      .roadmap_card_logo {
        margin-right: 0.5rem;

        max-width: 24px;
        width: 100%;
        height: 24px;
        background: #ccd74c;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-family: Maitree;
        font-weight: 700;
      }
      .roadmap_card_title {
        font-size: 16px;
        font-weight: 600;
        color: #3b474e;
        margin-top: 0.15rem;
      }
    }
    .roadmap_card_pills {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }
  }
`;

const RoadmapList = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="roadmap_column">
        <div className="roadmap_column_header">
          <div className="roadmap_column_color upcoming" />
          <div className="roadmap_column_title">
            <h2>{t("roadmap.upcoming")}</h2>
            <h6>
              {
                RoadmapData.filter((item) => item.category === "upcoming")
                  .length
              }{" "}
              {t("roadmap.task")}
            </h6>
          </div>
        </div>
        <RoadmapColumnList>
          {RoadmapData.map(
            (item) =>
              item.category === "upcoming" && (
                <div className="roadmap_card">
                  <div className="roadmap_card_header">
                    {item.logo ? (
                      <img
                        className="roadmap_card_logo"
                        style={{ background: "none" }}
                        src={item.logo}
                        alt={item.title}
                      ></img>
                    ) : (
                      <div
                        style={{
                          background: item.color,
                          color: item.white && "white",
                        }}
                        className="roadmap_card_logo"
                      >
                        {item.title[0]}
                      </div>
                    )}

                    <div className="roadmap_card_title">{item.title}</div>
                  </div>
                  <div className="roadmap_card_pills">
                    {item.pills.map((pill) => (
                      <RoadmapPill title={pill} />
                    ))}
                  </div>
                </div>
              )
          )}
        </RoadmapColumnList>
      </div>
      <div className="roadmap_column">
        <div className="roadmap_column_header">
          <div className="roadmap_column_color inprogress" />
          <div className="roadmap_column_title">
            <h2>{t("roadmap.inProgress")}</h2>
            <h6>
              {
                RoadmapData.filter((item) => item.category === "inprogress")
                  .length
              }{" "}
              {t("roadmap.task")}
            </h6>
          </div>
        </div>
        <RoadmapColumnList>
          {RoadmapData.map(
            (item) =>
              item.category === "inprogress" && (
                <div className="roadmap_card">
                  <div className="roadmap_card_header">
                    {item.logo ? (
                      <img
                        className="roadmap_card_logo"
                        style={{ background: "none" }}
                        src={item.logo}
                        alt={item.title}
                      ></img>
                    ) : (
                      <div
                        style={{
                          background: item.color ? item.color : "#D4E7FF",
                          color: item.white && "white",
                        }}
                        className="roadmap_card_logo"
                      >
                        {item.title[0]}
                      </div>
                    )}

                    <div className="roadmap_card_title">{item.title}</div>
                  </div>
                  <div className="roadmap_card_pills">
                    {item.pills.map((pill) => (
                      <RoadmapPill title={pill} />
                    ))}
                  </div>
                </div>
              )
          )}
        </RoadmapColumnList>
      </div>
      <div className="roadmap_column">
        <div className="roadmap_column_header">
          <div className="roadmap_column_color completed" />
          <div className="roadmap_column_title">
            <h2>{t("roadmap.completed")}</h2>
            <h6>
              {
                RoadmapData.filter((item) => item.category === "completed")
                  .length
              }{" "}
              {t("roadmap.task")}
            </h6>
          </div>
        </div>
        <RoadmapColumnList>
          {RoadmapData.map(
            (item) =>
              item.category === "completed" && (
                <div className="roadmap_card">
                  <div className="roadmap_card_header">
                    {item.logo ? (
                      <img
                        className="roadmap_card_logo"
                        style={{ background: "none", borderRadius: "0" }}
                        src={item.logo}
                        alt={item.title}
                      ></img>
                    ) : (
                      <div
                        style={{
                          background: item.color ? item.color : "#6FCF97",
                          color: "white",
                        }}
                        className="roadmap_card_logo"
                      >
                        {item.title[0]}
                      </div>
                    )}

                    <div className="roadmap_card_title">{item.title}</div>
                  </div>
                  <div className="roadmap_card_pills">
                    {item.pills.map((pill) => (
                      <RoadmapPill title={pill} />
                    ))}
                  </div>
                </div>
              )
          )}
        </RoadmapColumnList>
      </div>
    </Wrapper>
  );
};

export default RoadmapList;
