import React from "react";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "../components/theme";
import styled from "styled-components";

//Components
import MetaComponent from "../components/MetaComponent";
import Menu from "../components/Menu";
import HeroGeneral from "../components/heroGeneral";
import heroBackgound from "../images/roadmap_newbg.jpg";
import heroBackgoundMobile from "../images/roadmap_newbg_mobile.jpg";
import Footer from "../components/Footer";

import "./index.css";
import RoadmapList from "../components/roadmap/RoadmapList";
import Button from "../components/Button";

import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  background: white;
  overflow: hidden;
  position: relative;
`;

const RoadmapButton = styled.div`
  margin: 1rem 0 4rem;
  display: flex;
  justify-content: center;
`;

const RoadmapPage = () => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={lightTheme}>
      <MetaComponent pageName="Roadmap" />
      <Wrapper>
        <Menu activeMenu="roadmap" />
        <HeroGeneral
          title={t("homepage.footer.roadmap")}
          background={heroBackgound}
          backgoundmb={heroBackgoundMobile}
        />
        <RoadmapList />
        {/* <RoadmapButton>
          <a
            href="https://docs.keyfi.com/roadmap"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button primary>Detailed Roadmap</Button>
          </a>
        </RoadmapButton> */}
        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
};

export default RoadmapPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
